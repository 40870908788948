import AGREEMENT from "../src/files/agreement.pdf";

var SERVER_URL = "http://127.0.0.1:8000";
var OFFLINE = true;
const ONE_FILE_MODE = true;
const MODE_MENU = false;
const DEFAULT_MODE = "commandclipsCheck";
const ERROR_IF_NOT_EDITED = false;


export { SERVER_URL, OFFLINE, ONE_FILE_MODE, MODE_MENU, DEFAULT_MODE, ERROR_IF_NOT_EDITED, AGREEMENT };
